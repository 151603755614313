import {
  ArrowRight,
  ArrowsHorizontal,
  CheckboxIndeterminate,
  CheckmarkOutline,
  CheckmarkOutlineWarning,
  CloudOffline,
  Edit,
  Help,
  Idea,
  Launch,
  Logout,
  ProgressBarRound,
  SecurityServices,
  User,
} from '@carbon/icons-react'
import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon'
import { Avatar, IconButton } from 'australis'
import { DropdownMenu } from 'australis'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useTelehealthStore } from 'src/blocks/Zoom/hooks/useTelehealthStore'
import { ZoomSessionState } from 'src/blocks/Zoom/types'
import { useAuth } from 'src/hooks/useAuth'
import { useAvailableFeatures } from 'src/hooks/useAvailableFeatures'
import { useBusinessRouter } from 'src/hooks/useBusinessRouter'
import { useCurrentBusiness } from 'src/hooks/useCurrentBusiness'
import { useCurrentStaff } from 'src/hooks/useCurrentStaff'
import {
  useCurrentUser,
  useCurrentUserPeriodicalQuery,
} from 'src/hooks/useCurrentUser'
import { usePlatformStatus } from 'src/hooks/usePlatformStatus'
import { StaffRole, UserRole } from 'src/types/graphql'

type UserMenuItem = {
  key: string
  label: string
  action?: () => void
  icon?: CarbonIconType
  items?: Array<Omit<UserMenuItem, 'items'>>
}

const ICON_SIZE = 18

export const UserMenu = () => {
  const currentStaff = useCurrentStaff()
  const currentUser = useCurrentUser()
  const { currentBusiness } = useCurrentBusiness()
  const availableFeatures = useAvailableFeatures()
  const { logout } = useAuth()
  const businessRouter = useBusinessRouter()
  const router = useRouter()
  const [telehealthSessionState] = useTelehealthStore((state) => [
    state.appointmentId,
    state.sessionState,
  ])
  const canAccessAdmin =
    currentUser?.role === UserRole.Superadmin ||
    currentUser?.role === UserRole.SupportAgent
  const platformStatus = usePlatformStatus()
  const allBusinesses = useCurrentUserPeriodicalQuery()
  const allStaffsSorted = useMemo(() => {
    const staffs = [...(allBusinesses.data?.currentUser?.staffs || [])]
    return staffs.sort((a, b) => {
      return a.business.name.localeCompare(b.business.name)
    })
  }, [allBusinesses.data])

  const platformStatusIcons: Record<string, CarbonIconType> = {
    online: CheckmarkOutline,
    maintenance: CheckmarkOutlineWarning,
    degraded: CheckmarkOutlineWarning,
    downtime: CloudOffline,
  }
  const platformStatusLabels: Record<string, string> = {
    online: 'Healthy',
    maintenance: 'Planned Maintenance',
    degraded: 'Partially Degraded',
    downtime: 'Outage',
  }

  /**
   * Predefined actions for the menu items.
   * You can call any function directly these are shorthand for common actions.
   */
  const actions = useMemo(
    () => ({
      /**
       * Navigate to a path from the root of the application.
       */
      path: (path: string) => () => router.push(path),

      /**
       * Navigate to a path within the current business.
       */
      businessPath: (path: string) => () => businessRouter.push(path),

      /**
       * Navigate without using the next router.
       * This will cause a full page reload.
       */
      internalUrl: (url: string) => () => (window.location.href = url),

      /**
       * Open a url in a new tab/window.
       */
      externalUrl: (url: string) => () => window.open(url, '_blank'),
    }),
    // businessRouter and router never actually change reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const menuItems: Array<UserMenuItem> = [
    {
      key: 'view-profile',
      action: actions.businessPath(`/staff/${currentStaff?.id}/appointments`),
      label: 'View My Profile',
      icon: User,
    },
    {
      key: 'profile.edit',
      label: 'Edit My Profile',
      action: actions.businessPath(`/settings/me/profile`),
      icon: Edit,
    },
  ]

  // Help
  if (availableFeatures['help.menu']) {
    menuItems.push({
      key: 'help',
      label: 'Help',
      icon: Help,
      items: [
        {
          key: 'help-center',
          label: 'Help Center',
          action: actions.externalUrl('https://help.upvio.com'),
          icon: Help,
        },
        {
          key: 'platform-status',
          action: actions.businessPath('/status'),
          icon: platformStatusIcons[platformStatus],
          label: `Platform Status (${platformStatusLabels[platformStatus].replace(/^\w/, (c) => c.toUpperCase())})`,
        },
      ].filter(Boolean) as Array<UserMenuItem>,
    })
  }

  // Add business switcher if user has multiple businesses
  if (
    currentUser?.businessesCount !== 0 &&
    telehealthSessionState !== ZoomSessionState.Connected
  ) {
    menuItems.push({
      key: 'switch-business',
      label: 'Switch Business',
      icon: ArrowsHorizontal,
      items: [
        ...allStaffsSorted.map((staff) => {
          const isCurrentBusiness = currentBusiness?.id === staff.business.id
          const path = businessRouter.asPath.replace(
            businessRouter.basePath,
            '',
          )
          const staffRoles = staff.business.staffRoles || []
          const staffRole = staffRoles.includes(StaffRole.Owner)
            ? 'owner'
            : staffRoles.includes(StaffRole.Admin)
              ? 'admin'
              : null
          const label = `${staff.business.name}${staffRole ? ` [${staffRole}]` : ''}`

          return {
            key: `switch-business-${staff.business.alias}`,
            label,
            action: actions.internalUrl(
              `/businesses/${staff.business.alias}${path}`,
            ),
            icon: isCurrentBusiness ? ArrowRight : undefined,
          }
        }),
        {
          key: 'view-all-businesses',
          label: 'View All Businesses',
          action: actions.internalUrl('/businesses'),
        },
      ],
    })
  }

  // Add admin items
  if (canAccessAdmin) {
    menuItems.push({
      key: 'admin',
      label: 'Upvio Admin',
      icon: SecurityServices,
      items: [
        {
          key: 'admin',
          label: 'Admin Panel',
          action: actions.path('/admin'),
          icon: SecurityServices,
        },
        currentBusiness?.id
          ? {
              key: 'edit-business',
              label: 'Edit Business',
              action: actions.path(
                `/admin#/businesses/${currentBusiness.id}/show`,
              ),
              icon: CheckboxIndeterminate,
            }
          : null,
        currentBusiness?.id
          ? {
              key: 'edit-business-features',
              label: 'Edit Business Features',
              action: actions.path(
                `/admin#/businesses/${currentBusiness.id}/show/features`,
              ),
              icon: CheckboxIndeterminate,
            }
          : null,
      ].filter(Boolean) as Array<UserMenuItem>,
    })
  }

  menuItems.push({
    key: 'logout',
    label: 'Logout',
    action: () => logout(),
    icon: Logout,
  })

  /**
   * Look up the action in the menu items list to figure out what to do.
   * Submenu item actions will be prefixed with the parent key and split with a pipe.
   */
  const doAction = (actionKey: string) => {
    const [menuItemKey, submenuItemKey] =
      actionKey.indexOf('|') !== -1 ? actionKey.split('|') : [actionKey, null]
    const menuItem = menuItems.find((item) => item.key === menuItemKey)
    const submenuItem = submenuItemKey
      ? menuItem?.items?.find((item) => item.key === submenuItemKey)
      : null
    const action = submenuItem ? submenuItem.action : menuItem?.action

    // This should never happen because of types, but as a backup to avoid exceptions
    if (!action) {
      console.error('No action found for key:', actionKey)
      return
    }

    action()
  }

  return (
    <DropdownMenu>
      <IconButton color='secondary' size='md' shape='circle'>
        <Avatar
          name={currentStaff?.name || 'User'}
          size='md'
          variant='circle'
          borderColor='primary'
        >
          {currentStaff?.picture && (
            <Image
              width={40}
              height={40}
              src={currentStaff.picture.url}
              alt={`${currentStaff.name} profile picture`}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </Avatar>
      </IconButton>
      <DropdownMenu.Menu
        onAction={(key) => doAction(key.toString())}
        items={menuItems}
      >
        {(item) =>
          item.items ? (
            <DropdownMenu.SubmenuTrigger key={item.key} targetKey={item.key}>
              <DropdownMenu.Option key={item.key} textValue={item.label}>
                {item.icon ? (
                  <span style={{ marginRight: '0.5rem' }}>
                    <item.icon size={ICON_SIZE} />
                  </span>
                ) : null}
                <span>{item.label}</span>
              </DropdownMenu.Option>
              <DropdownMenu.Menu onAction={(key) => doAction(key.toString())}>
                {item.items.map((subitem) => {
                  const subitemKey = `${item.key}|${subitem.key}`
                  return (
                    <DropdownMenu.Option
                      key={subitemKey}
                      textValue={subitem.label}
                    >
                      {subitem.icon ? (
                        <span style={{ marginRight: '0.5rem' }}>
                          <subitem.icon size={ICON_SIZE} />
                        </span>
                      ) : null}
                      <span>{subitem.label}</span>
                      {subitem.key.indexOf('http') === 0 && (
                        <span style={{ marginLeft: 'auto' }}>
                          <Launch size={16} />
                        </span>
                      )}
                    </DropdownMenu.Option>
                  )
                })}
              </DropdownMenu.Menu>
            </DropdownMenu.SubmenuTrigger>
          ) : (
            <DropdownMenu.Option key={item.key} textValue={item.label}>
              {item.icon ? (
                <span style={{ marginRight: '0.5rem' }}>
                  <item.icon size={ICON_SIZE} />
                </span>
              ) : null}
              <span>{item.label}</span>
              {item.key.indexOf('http') === 0 && (
                <span style={{ marginLeft: 'auto' }}>
                  <Launch size={16} />
                </span>
              )}
            </DropdownMenu.Option>
          )
        }
      </DropdownMenu.Menu>
    </DropdownMenu>
  )
}
