import type {
  ChatClient,
  Stream,
  VideoClient,
  RecordingClient,
} from '@zoom/videosdk'

export type ZoomClient = typeof VideoClient
export type ZoomChatClient = typeof ChatClient
export type ZoomRecordingClient = typeof RecordingClient
export type ZoomStream = typeof Stream

export type ZoomDesiredState = {
  sendVideoQuality: 480 | 720 | 1080
}

export const STREAM_BACKGROUND_COLOR = '#2f303d'
export const SUPPORTED_RESOLUTIONS = [180, 360, 540, 720] as const
export const DEFAULT_ASPECT_RATIO = 16 / 9
export const DEFAULT_RESOLUTION = 360
export const TARGET_FPS = 30

export type SupportedResolution = (typeof SUPPORTED_RESOLUTIONS)[number]

export type ZoomStreamStats = {
  capturingVideo: boolean
  isMuted: boolean
}

export enum ZoomSessionState {
  Init = 'init',
  Prevented = 'prevented',
  Connecting = 'connecting',
  Initialized = 'initialized',
  Connected = 'connected',
  Disconnecting = 'disconnecting',
  Disconnected = 'disconnected',
  Errored = 'errored',
}

export type ZoomRecordingStatus =
  | 'Recording'
  | 'Paused'
  | 'Stopped'
  | 'Ask'
  | 'Accept'
  | 'Decline'

export enum ZoomUserRole {
  Staff = 'staff',
  Patient = 'patient',
}

export enum ZoomUIZIndex {
  ParticipantVideoStream = 200,
  SelfVideoStream = 400,
  RecordingStatusIndicator = 500,
  SettingsOverlay = 700,
  VideoStreamErrors = 800,
}

export type ShareScreenState = {
  /**
   * Sharing state.
   */
  state: 'Active' | 'Inactive'
  /**
   * User ID of active share.
   */
  userId?: number
}

/**
 * Audio connection goes through various states before a stable connection is established.
 */
export enum AudioState {
  Idle = 'idle',

  /**
   * Once codecs are ready for encoding and decoding, audio stream can be joined.
   * Trying to join before this state will result in an error.
   * Trying to mute/unmute before this state will result in an error.
   */
  Ready = 'ready',

  /**
   * Transition to joining state. This can take a while so the UI can react.
   */
  Joining = 'joining',

  /**
   * Audio streaming is connected. Channels can be muted/unmuted in this state.
   */
  Joined = 'joined',

  /**
   * Sometimes audio codecs can have errors, or no audio device is detected.
   */
  Error = 'error',
}

/**
 * Track the state of the telehealth view states
 * */
export enum TelehealthViewState {
  preview = 'preview',
  inSession = 'in_session',
  summary = 'summary',
}

/**
 * Response from /v0/telehealth/sessions
 */
export interface V0TelehealthSession {
  id: string
  appointmentId?: string
  appointment?: {
    id: string
    token?: string
    date: Date
    duration: number
    patientTimezone: string
    business: {
      id: string
      alias?: string
      name: string
    }
    patient?: {
      id: string
      name: string
      email: string
    }
    staff: {
      id: string
      alias?: string
      name: string
    }
    service: {
      id: string
      alias?: string
      name: string
      description: string
    }
  }
  businessId: string
  staffId?: string
  provider: string
  role: ZoomUserRole
  token: string
  displayName: string
  createdAt: Date
  updatedAt: Date
}
