import { createRef } from 'react'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { AudioState, ZoomSessionState, ZoomUserRole } from '../types'

import {
  type ZoomClient,
  type ZoomChatClient,
  type ZoomDesiredState,
  type ZoomStream,
  type ZoomStreamStats,
  type V0TelehealthSession,
  type ZoomRecordingClient,
  type ZoomRecordingStatus,
  type ShareScreenState,
  TelehealthViewState,
} from '../types'

import type {
  ChatMessage,
  Participant,
  ActiveSpeaker,
  LocalAudioTrack as ZoomLocalAudioTrack,
  LocalVideoTrack as ZoomLocalVideoTrack,
} from '@zoom/videosdk'

export interface LocalVideoTrack extends ZoomLocalVideoTrack {
  // missing from the ZoomLocalVideoTrack type
  readonly deviceId: string
  readonly isVideoStarted: boolean
}

export interface LocalAudioTrack extends ZoomLocalAudioTrack {
  // missing from the ZoomLocalAudioTrack type
  readonly isAudioStarted: boolean
  readonly isMicUnmuted: boolean
}

/**
 * Generic zustand setter to avoid having to type out the full setter type.
 */
type Setter<T> = (value: T) => void

interface TelehealthStoreState {
  businessId: string | null
  setBusinessId: Setter<string>
  appointmentId: string | null
  setAppointmentId: Setter<string>
  userRole: ZoomUserRole
  setUserRole: Setter<ZoomUserRole>

  desiredState: ZoomDesiredState
  setDesiredState: Setter<ZoomDesiredState>

  client: ZoomClient | null
  setClient: Setter<ZoomClient | null>
  chatClient: ZoomChatClient | null
  setChatClient: Setter<ZoomChatClient | null>
  recordingClient: ZoomRecordingClient | null
  setRecordingClient: Setter<ZoomRecordingClient | null>

  currentView: TelehealthViewState
  setCurrentView: Setter<TelehealthViewState>

  /**
   * The userId assigned to the currently authed user.
   */
  userId: number | null
  setUserId: Setter<number | null>

  participants: Participant[]
  setParticipants: Setter<Participant[]>
  activeParticipantId: number | null
  setActiveParticipantId: Setter<number | null>

  stream: ZoomStream | null
  setStream: Setter<ZoomStream | null>
  streamStats: ZoomStreamStats
  setStreamStats: Setter<ZoomStreamStats>
  audioState: AudioState
  setAudioState: Setter<AudioState>

  recordingStatus: ZoomRecordingStatus
  setRecordingStatus: Setter<ZoomRecordingStatus>

  /**
   * Since we need to pass this to the startVideo function which is headless, a
   * ref must be stored globally so it can be used after eing mounted into the UI.
   * If this element does not exist, but video element rendering is required then
   * the startVideo must not be called and an error should be shown.
   *
   * https://developers.zoom.us/docs/video-sdk/web/video/#render-self-view
   */
  selfVideoRef: React.RefObject<HTMLVideoElement> | null
  renderSelfWithVideoElement: boolean
  setRenderSelfWithVideoElement: Setter<boolean>

  /**
   * Since we need to pass this to the startShareScreen function which is headless, a
   * ref must be stored globally so it can be used after being mounted into the UI.
   *
   * https://developers.zoom.us/docs/video-sdk/web/share/#render-user-screen-sharing
   */
  screenShareCanvasRef: React.RefObject<HTMLCanvasElement> | null
  screenShareVideoRef: React.RefObject<HTMLVideoElement> | null

  session: V0TelehealthSession | null
  setSession: Setter<V0TelehealthSession | null>
  sessionState: ZoomSessionState
  setSessionState: Setter<ZoomSessionState>
  sessionError: string | null
  setSessionError: Setter<string | null>

  // active-speaker client event
  activeStreamSpeaker: ActiveSpeaker[] | null
  setActiveStreamSpeaker: Setter<ActiveSpeaker[]>

  shareScreenState: ShareScreenState
  setShareScreenState: Setter<ShareScreenState>

  displayMobileDrawer: boolean
  setDisplayMobileDrawer: Setter<boolean>
  toggleMobileDrawer: () => void

  chatMessages: ChatMessage[]
  setChatMessages: Setter<ChatMessage[]>
  chatMessagesUnreadCount: number
  setChatMessagesUnreadCount: Setter<number>
  incrementChatMessagesUnreadCount: () => void

  // local video/audio tracks, used for previewing
  localAudioTrack: LocalAudioTrack | null
  createLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>
  destroyLocalAudioTrack: () => void

  localVideoTrack: LocalVideoTrack | null
  createLocalVideoTrack: (deviceId?: string) => Promise<LocalVideoTrack>
  destroyLocalVideoTrack: () => void
}

/**
 * Primary store for all telehalth related context.
 */
export const useTelehealthStore = createWithEqualityFn<TelehealthStoreState>(
  (set) => ({
    // local audio tracks, used for previewing
    localAudioTrack: null,
    createLocalAudioTrack: async (deviceId) => {
      try {
        const { default: ZoomVideo } = await import('@zoom/videosdk')
        const localAudioTrack = ZoomVideo.createLocalAudioTrack(
          deviceId,
        ) as LocalAudioTrack
        set({ localAudioTrack })
        return localAudioTrack
      } catch (err) {
        console.error('Failed to create local audio track:', err)
        set({ localAudioTrack: null })
        throw err
      }
    },
    destroyLocalAudioTrack: () => {
      set((state) => {
        if (state.localAudioTrack?.isAudioStarted) {
          state.localAudioTrack.stop()
        }
        return { localAudioTrack: null }
      })
    },

    // local video tracks, used for previewing
    localVideoTrack: null,
    createLocalVideoTrack: async (deviceId) => {
      try {
        const { default: ZoomVideo } = await import('@zoom/videosdk')
        const localVideoTrack = ZoomVideo.createLocalVideoTrack(
          deviceId,
        ) as LocalVideoTrack
        set({ localVideoTrack })
        return localVideoTrack
      } catch (err) {
        console.error('Failed to create local video track:', err)
        set({ localVideoTrack: null })
        throw err
      }
    },
    destroyLocalVideoTrack: () => {
      set((state) => {
        if (state.localVideoTrack?.isVideoStarted) {
          state.localVideoTrack.stop()
        }
        return { localVideoTrack: null }
      })
    },

    // track current view screen state
    currentView: TelehealthViewState.preview,
    setCurrentView: (currentView: TelehealthViewState) => set({ currentView }),

    businessId: null,
    setBusinessId: (businessId) => set({ businessId }),
    appointmentId: null,
    setAppointmentId: (appointmentId) => set({ appointmentId }),
    userRole: ZoomUserRole.Patient,
    setUserRole: (userRole: ZoomUserRole) => set({ userRole }),

    desiredState: {
      sendVideoQuality: 480,
    },
    setDesiredState: (desiredState: ZoomDesiredState) => set({ desiredState }),

    client: null,
    setClient: (client) => set({ client }),
    chatClient: null,
    setChatClient: (chatClient) => set({ chatClient }),
    recordingClient: null,
    setRecordingClient: (recordingClient) => set({ recordingClient }),
    userId: null,
    setUserId: (userId: number | null | undefined) =>
      set((state) => {
        const nonSelfParticipantIds = state.participants
          .filter((p) => p.userId !== userId)
          .map((p) => p.userId)
        const activeParticipantId =
          state.activeParticipantId || nonSelfParticipantIds[0] || null
        return { userId, activeParticipantId }
      }),

    participants: [],
    setParticipants: (participants: Participant[]) =>
      set((state) => {
        const authedParticipantId = state.userId
        const nonSelfParticipantIds = participants
          .filter((p) => p.userId !== authedParticipantId)
          .map((p) => p.userId)
        const firstNonSelfParticipantId = nonSelfParticipantIds[0] || null
        let activeParticipantId =
          state.activeParticipantId || firstNonSelfParticipantId || null
        if (!authedParticipantId) {
          activeParticipantId = null
        }
        if (
          activeParticipantId &&
          !nonSelfParticipantIds.includes(activeParticipantId)
        ) {
          activeParticipantId = firstNonSelfParticipantId || null
        }
        return { activeParticipantId, participants }
      }),
    activeParticipantId: null,
    setActiveParticipantId: (activeParticipantId) =>
      set({ activeParticipantId }),

    stream: null,
    setStream: (stream: ZoomStream | null) => set({ stream }),
    streamStats: {
      capturingVideo: false,
      isMuted: false,
    },
    setStreamStats: (streamStats: ZoomStreamStats) => set({ streamStats }),
    audioState: AudioState.Idle,
    setAudioState: (audioState: AudioState) => set({ audioState }),

    recordingStatus: 'Stopped',
    setRecordingStatus: (recordingStatus: ZoomRecordingStatus) =>
      set({ recordingStatus }),

    selfVideoRef: createRef<HTMLVideoElement>(),
    renderSelfWithVideoElement: false,
    setRenderSelfWithVideoElement: (render: boolean) =>
      set({ renderSelfWithVideoElement: render }),

    screenShareCanvasRef: createRef<HTMLCanvasElement>(),
    screenShareVideoRef: createRef<HTMLVideoElement>(),

    session: null,
    setSession: (session) => set({ session }),
    sessionState: ZoomSessionState.Init,
    setSessionState: (sessionState: ZoomSessionState) => set({ sessionState }),
    sessionError: null,
    setSessionError: (sessionError) => set({ sessionError }),

    activeStreamSpeaker: null,
    setActiveStreamSpeaker: (activeStreamSpeaker: ActiveSpeaker[]) =>
      set({ activeStreamSpeaker }),

    shareScreenState: { state: 'Inactive', userId: undefined },
    setShareScreenState: (shareScreenState) => set({ shareScreenState }),

    displayMobileDrawer: false,
    setDisplayMobileDrawer: (displayMobileDrawer) =>
      set({ displayMobileDrawer }),
    toggleMobileDrawer: () =>
      set((state) => ({ displayMobileDrawer: !state.displayMobileDrawer })),

    chatMessages: [],
    setChatMessages: (chatMessages: ChatMessage[]) => set({ chatMessages }),
    chatMessagesUnreadCount: 0,
    setChatMessagesUnreadCount: (chatMessagesUnreadCount) =>
      set({ chatMessagesUnreadCount }),
    incrementChatMessagesUnreadCount: () =>
      set((state) => ({
        chatMessagesUnreadCount: state.chatMessagesUnreadCount + 1,
      })),
  }),
  Object.is,
)

export const useTelehealthClient = () =>
  useTelehealthStore((state) => state.client, shallow)

export const useTelehealthChatClient = () =>
  useTelehealthStore((state) => state.chatClient, shallow)

export const useTelehealthRecordingClient = () =>
  useTelehealthStore((state) => state.recordingClient, shallow)

export const useTelehealthSession = () =>
  useTelehealthStore((state) => state.session, shallow)

export const useTelehealthStream = () =>
  useTelehealthStore((state) => state.stream, shallow)

export const useTelehealthParticipants = () =>
  useTelehealthStore((state) => state.participants, shallow)

/**
 * Select a specific participant by their provider userId.
 */
export const useTelehealthParticipant = (userId: number) => {
  const participants = useTelehealthParticipants()

  if (userId === -1) return null

  return participants.find((p) => p.userId === userId) ?? null
}

/**
 * Return the userId of the currently authed provider user.
 */
export const useTelehealthUserId = () => {
  return useTelehealthStore(
    (state) => state.userId,
    (a, b) => a === b,
  )
}

/**
 * Return the participant object for the currently authed provider user.
 */
export const useTelehealthSelfParticipant = () => {
  return useTelehealthParticipant(useTelehealthUserId() ?? -1)
}

/**
 * Returns the appointment linked to the active meeting.
 */
export const useTelehealthAppointment = () => {
  return useTelehealthStore(
    (state) => state.session?.appointment || null,
    shallow,
  )
}
